import React, { useState } from 'react'

import { Box, Grid, Typography, CardContent,Card} from '@mui/material';
import LabTabs from './Components/TabView';
import { Container } from '@mui/system';



const Courses = () =>{

    return(
      <Container>
          <Box sx={{ flexGrow: 1 }}>
                <Grid 
                container
                // spacing={"20px"}
                // py="20px"
                sx={{
                    // backgroundColor:'primary.main',
                }}
                >
                    <Grid item xs={12}>
                    <Typography variant='h2' color={"secondary.contrastText"} textAlign={"center"}>LEAN SIGMA SIX COURSES</Typography>
                    </Grid>
                    <Grid 
                        item 
                        xs={12}
                        // display="flex"
                        // justifyContent="center"
                        // alignItems="center"
                        // alignContent="center"
                        >
                            <LabTabs/>
                    </Grid>
                </Grid>
            </Box>
      </Container>
    )
}


export default Courses ;