import React from "react";
import PropTypes from "prop-types";
import { Grid, Button, Typography, CardMedia ,Card, Box} from "@mui/material";



import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";

const HeroSlide = ({ slideImg, }) => {
  const theme = useTheme();
  return (
      <Grid
      container
      display="fle"
      justifyContent="center"
      alignItems="center"
      p={"10px"}

    >
   
      <Grid xs={12} md={12} sm={12} >
        
         <CardMedia
            image={slideImg}
            sx={{height:{xs:'150px',sm:"150px",md:"450px",lg:'600px'} ,padding:{xs:"0px",sm:"0px",md:'10px',lg:'20px'},}}
          />
 
              {/* <CardMedia
            image={slideImg}
            sx={{height:{xs:'80vh'} ,padding:{xs:"0px",sm:"0px",md:'10px',lg:'20px'},}}
          /> */}
 
          {/* <img
          style={{width:"100vh"}}
            src={slideImg}
          /> */}
      </Grid>
    </Grid>
  );
};

HeroSlide.propTypes = {
  slideImg: PropTypes.object.isRequired,
};

export default HeroSlide;
