import * as React from 'react';
import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import Tabs,{ tabsClasses }  from '@mui/material/Tabs';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import { Grid,Typography,Button,Container } from '@mui/material';
import CoursesComp from '../../../../Components/CoursesComp';






export default function LabTabs() {

   const [checked1, setChecked1] = React.useState(true);
   const [checked2, setChecked2] = React.useState(false);
   const [checked3, setChecked3] = React.useState(false);
   
   const CoursesData =[
      {
          id :2,
          button:'LSSGB',
          onclick:() => {setChecked1(true);setChecked2(false);setChecked3(false);} ,
          page:  <CoursesComp
          hours ={
             "45 hours"
          }
          weeks={
             "9 Weeks"
          }
          certifiedTitle={
             "Certified LEAN SIX SIGMA GREEN BELT TRAINING PROGRAM"
          }
          certifiedDetail={
             "Certified Lean Six Sigma Green Belt (CLSSGB) is intermediate program that prepares you to work on process improvement projects under the guidance of a Black Belt. LSSGB provides a thorough understanding of process improvement initiatives, under the guidance of a Lean Six Sigma Master Black Belt professionals. The course offers an in-depth training on the knowledge demonstration of Lean Six Sigma tools, with a LSSGB project"
          }
          keyasepectDetail={
             "An in-depth training includes Project Roadmap with project identification workshop and Project Management Simulation, Case studies - Industry specific scenarios, Group Exercise - Collaborative sessions, assessment preparation, Project completion for certification"
          }
          attendDetail={
             "Team Excellence Certified Lean Six Sigma training is ideal for professionals whose key focus area is Improvement. It can be Process / product or any business improvement. The Lean Six Sigma certification is for anyone who wishes to improve their skills as a leader in an organization while learning how to transform business processes to manage Quality, Cost and Delivery"
          }
          assesmentDetail={
             "Candidates knowledge will be assessed based on a Multiple-Choice Question (MCQ)-based online examination measuring the comprehension of Certified Lean Six Sigma Green Belt Knowledge."
          }
     />,
          checked:checked1
      },
      {
          id :1,
          button:'LSSBB',
          onclick:() =>  {setChecked1(false);setChecked3(false);setChecked2(true);},
          page:<CoursesComp
          hours ={
             '96 hours '
          }
          weeks={
             "19 Weeks"
          }
          certifiedTitle={
             'Certified LEAN SIX SIGMA Black BELT TRAINING PROGRAM'

          }
          certifiedDetail={
             "Certified Lean Six Sigma Black Belt (CLSSBB) is the advanced program that prepares you to manage and lead project teams under the guidance of a Master Black Belt. LSSBB provides a thorough understanding of process improvement initiatives, under the guidance of a Lean Six Sigma Master Black Belt professionals. The course offers an in-depth training on the knowledge demonstration of Lean Six Sigma tools, with a LSSBB project"

          }
          keyasepectDetail={
             'An in-depth training includes Project Roadmap with project identification workshop and Project Management Simulation, Case studies - Industry specific scenarios, Group Exercise - Collaborative sessions, assessment preparation, Project completion for certification'

          }
          attendDetail={
             "Team Excellence Certified Lean Six Sigma training is ideal for professionals whose key focus area is Improvement. It can be Process / product or any business improvement. The Lean Six Sigma certification is for anyone who wishes to improve their skills as a leader in an organization while learning how to transform business processes to manage Quality, Cost and Delivery"
          }
          assesmentDetail={
             "Candidates knowledge will be assessed based on a Multiple-Choice Question (MCQ)-based online examination measuring the comprehension of Certified Lean Six Sigma Green Belt Knowledge."
          }
     />,
          checked:checked2
      },
      {
          id :3,
          button:'LSSGB + LSSGB',
          onclick:() =>  {setChecked2(false);setChecked1(false);setChecked3(true);},
          page: <CoursesComp
          hours ={
             '120 hours '
          }
          weeks={
             "24 Weeks "
          }
          certifiedTitle={
             "Certified LEAN SIX SIGMA GREEN Belt + Black BELT TRAINING PROGRAM"
          }
          certifiedDetail={
             "Certified Lean Six Sigma Green Belt + Black Belt (CLSSGB + BB) is the blend of both intermediate and advanced program that provides a thorough understanding from Basics of lean six sigma to advanced program. It prepares you to manage and lead project teams under the guidance of a master black belt. LSSBB provides a thorough understanding of process improvement initiatives, under the guidance of a Lean Six Sigma Master Black Belt professionals. The course offers an in-depth training on the knowledge demonstration of Lean Six Sigma tools, with a LSSBB project "
          }
          keyasepectDetail={
             "An in-depth training includes Project Roadmap with project identification workshop and Project Management Simulation, Case studies - Industry specific scenarios, Group Exercise - Collaborative sessions, assessment preparation, Project completion for certification"

          }
          attendDetail={
             "Team Excellence Certified Lean Six Sigma training is ideal for professionals whose key focus area is Improvement. It can be Process / product or any business improvement. The Lean Six Sigma certification is for anyone who wishes to improve their skills as a leader in an organization while learning how to transform business processes to manage Quality, Cost and Delivery"
          }
          assesmentDetail={
             "Candidates knowledge will be assessed based on a Multiple-Choice Question (MCQ)-based online examination measuring the comprehension of Certified Lean Six Sigma Green Belt Knowledge" 
          }
     />,
          checked:checked3
      },
   ]
   
   
  return (
   <Container>
   <Box sx={{flexGrow:'1'}}>
     <Grid container spacing={2}>
             {
         CoursesData.map((item)=>(
             <Grid item xs={4} key={item.id}>
                   <Button style={{ backgroundColor:item.checked ? "#2979FF" :'#00000060' ,width:'100%',height:'50px',borderRadius:'0px'}} onClick={item.onclick}  variant="contained">{item.button}</Button>
             </Grid>
         ))
        }
     </Grid>
     {
         CoursesData.map((item) =>(
             <Box sx={{display:'flex',flexDirection:"row",justifyContent:"center"}} key={item.id} pt="10px"> 
             {item.checked && <Grid>{item.page}</Grid>}
             </Box>
         ))
     }
 </Box>
</Container>
  );
}