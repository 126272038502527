export const light = {
  alternate: {
    main: '#f7faff',
    dark: '#edf1f7',
  },
  cardShadow: 'rgba(23, 70, 161, .11)',
  mode: 'light',
  primary: {
    main: '#2979FF',    //'#377dff',
    light: '#989BA2',
    dark: '#2979FF',    // TODO_SP : Need to check if it is needed
    contrastText: '#ffffff',
  },
  secondary: {
    light: '#2979FF', //'#ffb74d',
    main: '#2979FF', //'#f9b934',
    dark: '#47B59E',
    contrastText: 'rgba(0, 0, 0, 0.87)',
  },
  text: {
    primary: '#000000', // Black Text
    secondary: '#707070', // Grey Text
    black: '#000000',
    grey: '#707070',
  },
  button: {
    primary: '#2979FF', // Black Text
    secondary: '#00000080', // Grey Text
  },
  background: {
    paper: '#ffffff',
    default: '#ffffff',
    level2: '#ffffff',
    level1: '#ffffff',
  },
};

export const dark = {
  alternate: {
    main: '#1a2138',
    dark: '#151a30',
  },
  cardShadow: '#000000',
  common: {
    black: '#000',
    white: '#fff',
  },
  mode: 'dark',
  primary: {
    main: '#2979FF',
    light: '#2979FF',
    dark: '#2979FF',
    contrastText: '#fff',
  },
  secondary: {
    light: '#FFEA41',
    main: '#FFE102',
    dark: '#DBBE01',
    contrastText: 'rgba(0, 0, 0, 0.87)',
  },
  text: {
    primary: '#000000',
    secondary: '#ffffff',
  },
  divider: 'rgba(255, 255, 255, 0.12)',
  background: {
    paper: '#FFFFFF',
    default: '#FFFFFF',
    level2: '#ffffff',
    level1: '#ffffff',
  },
};
