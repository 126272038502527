import * as React from 'react';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Typography from '@mui/material/Typography';
import ExpandCircleDownIcon from '@mui/icons-material/ExpandCircleDown';
import { Grid,Box} from '@mui/material';


const faqData=[
  {
    id:1,
    panel:"panel1",
    question:"What is different in this training? Training effectiveness of this training?",
    answer:"Industry expert trainers (LSSBB or LSSMBB) will help you develop deep understanding of each LSS tool, in the training, you will also have Collaborative And Interactive Learning Sessions Group Exercises, Brainstorming Sessions and workshops."
  },
  {
    id:2,
    panel:"panel2",
    question:"Does this training and certification include Hands - on Practice on a project and case studies?",
    answer:"Yes - We conduct detailed training so that you get Value For Money. Training includes Hands - on Practice, Project Identification Workshop, DMAIC Project Roadmap, Project Management Simulation, Case studies that includes Industry specific scenarios"
  },
  {
    id:3,
    panel:"panel3",
    question:"Is Lean Six Sigma Certification important?",
    answer:"Yes, however only a certificate is just a piece of paper. We need to build understanding to be able to make the best use of these improvement methodologies."
  },
  {
    id:4,
    panel:"panel4",
    question:"Do trainers have exposures / required experience?",
    answer:"Training is conducted specially by Highly Experienced Industry Experts as trainers with proficiency and combined rich experience of over 60 + years."
  },
  {
    id:5,
    panel:"panel5",
    question:"How will this training help me in my career?",
    answer:"You can Fast-Track your Career Growth  only when you will be able to apply what you learn to Drive Cost-Saving projects and Effective Process Management. This training will help you with this and more."
  },
  {
    id:6,
    panel:"panel6",
    question:"How do I identify Projects?",
    answer:"This training also has a Project Identification workshop and phase by phase DMAIC Project Roadmap from Project Storyboard to Project Presentation."
  },
]

 function ControlledAccordions() {
    const [expanded, setExpanded] = React.useState(false);
  
    const handleChange = (panel) => (event, isExpanded) => {
      setExpanded(isExpanded ? panel : false);
    };
  
    return (
      <Grid >
           {
             faqData.map((item) =>(
              <Grid py={2}>
              <Accordion
                  key={item.id}
                  expanded={expanded === item.panel}
                  onChange={handleChange(item.panel)}
                  sx={{borderColor:'rgba(0, 0, 0, 0.25)',}}
                     >
              <AccordionSummary
                expandIcon={<ExpandCircleDownIcon sx={{ color:"#2979FF"}} />}
                aria-controls="panel4bh-content"
                id="panel4bh-header"
              >
                <Typography 
                  variant='subtitle2' 
                  color={"secondary.contrastText"} 
                  sx={{ width: '100%', flexShrink: 0 }}
                >
                  {item.question}
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography  variant='caption1' color={"secondary.contrastText"}>
                     {item.answer}
                </Typography>
              </AccordionDetails>
             </Accordion>
             </Grid>
             ))
           }
      </Grid>
    );
  }


export default ControlledAccordions; 