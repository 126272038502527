import { Grid,Container } from "@mui/material";
import React, { Component } from "react";
import Slick from "react-slick";
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";

import HeroSlide from "../views/LandingPage/Home/Components/HeroSlide";

import Slide1 from '../assets/slider_1.png';
import Slide2 from '../assets/slider_2.png';
import Slide3 from '../assets/slider_3.png';
import Slide4 from '../assets/slider_4.png';
import Slide5 from '../assets/slider_5.png';
import Slide6 from '../assets/slider_6.png';
import { red, yellow } from "@mui/material/colors";


function SampleNextArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{ ...style, display: "block", backgroundColor:'#2979FF',borderRadius:'20px' }}
      onClick={onClick}
    />
  );
}

function SamplePrevArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{ ...style, display: "block", backgroundColor:'#2979FF',borderRadius:'20px'}}
      onClick={onClick}
    />
  );
}
 
const SlideData =[
    {
        id:1,
        name:Slide1,
    },
    {
        id:2,
        name:Slide2,
    },
    {
        id:3,
        name:Slide3,
    },
    {
        id:4,
        name:Slide4,
    },
    {
        id:5,
        name:Slide5,
    },
    {
        id:6,
        name:Slide6,
    }
]


export default class SimpleSlider extends Component {
  render() {
    const settings = {
        dots: true,
        infinite: true,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 2000,
        arrows: true,
        fade:true,
        // padding:"20px",
        adaptiveHeight: true,
        nextArrow: <SampleNextArrow style={{color:'red'}}/>,
        prevArrow: <SamplePrevArrow />
    };
    return (
      <Grid 
      container
      display="flex"
      justifyContent="center"
      >
         <Grid item xs={12} sm={12} md={12}  >
          <Container>
          <Slick {...settings}>
            {
                SlideData.map((item) =>(
                <Grid>
                        <div style={{}} key={item.id}>
                            <HeroSlide
                                slideImg={item.name}
                            />
                         </div>
                </Grid>
                ))
            }
        </Slick>
          </Container>
        </Grid>
      </Grid>
    );
  }
}