import React, { useState } from 'react'
import { Box,Grid,Typography,Container} from '@mui/material';
import ControlledAccordions from './Components/ControlledAccordions';

// import FAQ_Image from '../../../assets/faq.png'

const FAQ = () =>{

    return(
        <Box 
            sx={{ 
                flexGrow: 1 ,
                backgroundColor:'primary.main',
                py:"20px"
              }}
        >
            <Container>
                    <Grid container spacing={2}>
                        <Grid item xs={12} sm={12} md={12}>
                        <Typography variant='h2' color={"primary.contrastText"} textAlign={"start"}>FAQ</Typography>
                        </Grid>
                        <Grid item xs={12} sm={12} md={12}>
                            <ControlledAccordions/>
                        </Grid>
                        {/* <Grid 
                            item 
                            xs={12}
                            sm={12}
                            md={5}
                            display="flex"
                            justifyContent="center"
                            alignItems="center"
                            >
                            <img
                            alt='FAQ'
                            src={FAQ_Image }
                            />
                        </Grid> */}
                    </Grid>
            </Container>
      </Box>
    )
}


export default FAQ ;