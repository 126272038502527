import React, { useState ,useRef} from 'react'
import { Container, Grid} from '@mui/material';

import WhytrainWithus from './WhytrainWithus/WhytrainWithus';
import Courses from './Courses/Courses';
import FAQ from './FAQ/FAQ';
import About from './About/About';
import Contact from './Contact/Contact';
import Main from '../../layouts/Main/Main';
import Home from './Home/Home';
import Cssc from './CSSCSection/Cssc';




const LandingPage = () =>{
    const whyTrain = useRef(null)
    const homesix = useRef(null)
    const courses = useRef(null)
    const faq = useRef(null)
    const about = useRef(null)
    const contact = useRef(null)

    return(
        <Main 
           whyTrain= {whyTrain}
           homesix= {homesix} 
           courses= {courses} 
           faq= {faq} 
           about= {about}
           contact= {contact}
           > 
              <Grid
            container
            direction="column"
            justifyContent="center"
            alignItems="center"
        >
            <Grid container spacing={"40px"} sx={{}}>
                            <Grid item xs={12} sm={12} md={12} >
                                <section id="Home"  ref={homesix}>
                                    <Home/>
                                </section>
                            </Grid>
                            <Grid item xs={12} sm={12} md={12}>
                                <section >
                                   <Cssc/>
                                </section>
                            </Grid>
                            <Grid item xs={12} sm={12} md={12}>
                                <section ref={whyTrain} id="whytrainwithus">
                                    <WhytrainWithus />
                                </section>
                            </Grid>
                            <Grid item xs={12} sm={12} md={12} >
                                <section ref={courses} id="Course">
                                    <Courses/>
                                </section>
                            </Grid>
                            <Grid item xs={12} sm={12} md={12} >
                                <section ref={faq} id="FAQ">
                                    <FAQ/>
                                </section>
                            </Grid>
                            <Grid item xs={12} sm={12} md={12} >
                                <section ref={about} id="aboutus">
                                    <About/>
                                </section>
                            </Grid>
                            <Grid item xs={12} sm={12} md={12} >
                                <section ref={contact} id="contact">
                                    <Contact/>
                                </section>
                            </Grid>
                    </Grid>
            </Grid>
        </Main>
    )
}


export default LandingPage ;