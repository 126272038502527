import React, { useState } from 'react'

import { Box, Container, Grid, Typography } from '@mui/material';
import Trainus from './Components/Trainus';
import Benefits from './Components/Benefits';




const WhytrainWithus = () =>{
    return(
                <Grid
                container
                >
                 <Container>
                    <Grid item xs={12} sm={12} md={12} pb={"40px"}>
                        <Trainus/>
                    </Grid>
                </Container>
                <Grid 
                item  
                    xs={12} 
                    sm={12} 
                    md={12} 
                    sx={{ backgroundColor:'primary.main',}}
                >
                    <Benefits/>
                </Grid>
            </Grid>
    )
}


export default WhytrainWithus ;