import React from 'react';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Link from '@mui/material/Link';
import Typography from '@mui/material/Typography';
import { useTheme } from '@mui/material/styles';

const Footer = () => {
  const theme = useTheme();
  const { mode } = theme.palette;

  return (
    <Grid container spacing={2} textAlign={"center"}>
      <Grid item xs={12} md={12} sm={12}>
        <Typography
          align={'center'}
          variant={'caption2'}
          color="text.secondary"
          gutterBottom
        >
          Powered by Team Excellence
        </Typography>
      </Grid>
    </Grid>
  );
};

export default Footer;
