import React from "react";
import PropTypes from "prop-types";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid"
import { useTheme } from "@mui/material/styles";

import TELogo from '../../../../../../assets/logo.png';

const navItems = [
  {
   id:1,
   name:'Home',
   link:'#'
  },
  {
   id:2,
   name:'Why train with Us',
   link:'#whytrainwithus'
  },
  {
   id:2,
   name:'Course',
   link:'#Course'
  },
  {
   id:2,
   name:'FAQ',
   link:'#FAQ'
  },
  {
   id:2,
   name:'About Us',
   link:'#aboutus'
  },
  {
   id:3,
   name:'Contact Us',
   link:'#contact'
  },
 ];

const SidebarNav = ({ pages }) => {
  const theme = useTheme();
  const { mode } = theme.palette;



  return (
    <Box>
      <Box width={1} paddingX={2} paddingY={1} backgroundColor={"primary.contrastText"}>
      <Box
        display={"flex"}
        component="a"
        href="#Home"
        title="TELOGO"
        width={{ xs: 140, md: 160 }}
      >
         <Box
          component={"img"}
          src={TELogo}
          height={1}
          width={1}
        />
        </Box>
      </Box>
      <Grid 
        paddingX={0} 
        paddingY={2}
        flexDirection={"column"}
        justifyContent={"center"}
        alignContent={"center"}
        display={"flex"}
        >
      {navItems.map((item) => (
        <Grid item xs={12} sx={12}>
              <Button key={item.id} href={item.link} sx={{ color: "#primary.main",px:4  }}>
                {item.name}
              </Button>
              <Grid
               sx={{
                borderBottom:"solid",
                borderColor:"#828384",
                borderWidth:0.5
             }}
              />
        </Grid>
               ))}
      </Grid>
    </Box>
  );
};

SidebarNav.propTypes = {
  pages: PropTypes.object.isRequired,
};

export default SidebarNav;
