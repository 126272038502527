import React, { useState } from 'react'
import {
    Box,
    Typography,
    Grid,
    Container
}  from '@mui/material';
import BookAppointment from './Components/BookAppointment';
import FeedbackForm from './Components/FeedbackForm';

import ContactBackground from '../../../assets/contact_us_background.png'


const Contact = () =>{

    return(
        <Grid
        style={{
            backgroundImage:`url(${ContactBackground})`,
            backgroundSize: "cover",
        }}
        >
          <Container>
        <Grid>
        <Grid container>
              <Grid  item  xs={12}  sm={5} md={5} mt={"109px"} >
                <BookAppointment/>
              </Grid>  
              {/* <Grid item  xs={12}  sm={2} md={2}></Grid> */}
                <Grid   container item xs={12} sm={7} md={7} py={"50px"} >
                   <FeedbackForm/>
                </Grid>
              </Grid> 
            </Grid>
          </Container>
        </Grid>

    )
}



export default Contact ;