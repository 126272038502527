import React, { useState } from 'react'
import { Box, Grid, Typography} from '@mui/material';

import Task from '../../../../assets/task_complate_icon.png';
import { Container } from '@mui/system';


const benefitsData =[
    {
        id:1,
        title:'Learning and Career benefits',
        subtitle:'Training and Certification opens numerous career opportunities across different fields',
    },
    {
        id:2,
        title:'Fast-Track Career Growth',
        subtitle:'Drive Cost-Savings and Effective Process Management',
    },
    {
        id:3,
        title:'Building Impressive Profile',
        subtitle:'Become a Certified Lean Six Sigma Green Belt or Certified Lean Six Sigma Black Belt',
    },
    {
        id:4,
        title:'Visibility Across Organization To Get Noticed',
        subtitle:'Drive improvement and solve complex problems using statistics',
    },
    {
        id:5,
        title:'Practical Application',
        subtitle:'Hands-on experience on Lean Six Sigma Tools and Techniques. Hands on Experience from simulation project with end-to-end project support',
    },
    {
        id:6,
        title:'Competitive Edge',
        subtitle:'Get In-depth knowledge and understanding, thoroughly absorb the content of LSS Course. Gather the requisite skills to engage and effectively manage leadership stakeholders and clients',
    },
    {
        id:7,
        title:'MBB Mentor',
        subtitle:'Guidance from certified Master Black Belts (MBBs) with multi-industry exposure and extensive project knowledge',
    },
    {
        id:8,
        title:'Master The Art of LSS Project Management',
        subtitle:'Project Identification workshops, DMAIC Project Roadmap, Project Storyboard, Project Presentation.',
    },
    {
        id:9,
        title:'Face Any Interview To Answer Technical Interview Questions',
        subtitle:'In-depth knowledge and understanding to build conceptual clarity. Become familiar with core change management skills',
    },
    
]


const Benefits = () =>{

    return(
        <Container>
            <Box sx={{ flexGrow: 1 }}>
                    <Grid 
                    container
                    spacing={"20px"}
                    py="20px"
                    sx={{
                    
                    }}
                    >
                        <Grid item xs={12}>
                        <Typography variant='h2' color={"primary.contrastText"} textAlign={"center"}>BENEFITS OF LEARNING</Typography>
                        </Grid>
                        {
                            benefitsData.map((item) => (
                                <Grid item xs={12} sm={12} md={6} key={item.id}>
                                <Grid 
                                    container
                                    spacing={"10px"}
                                >
                                <Grid item xs={2}  sm={2} md={1.5}>
                                    <img
                                    alt='Task'
                                    src={Task}
                                    //  style={{width:"30px" ,height:'30px'}}
                                    />
                                </Grid>
                                <Grid item  xs={10} sm={10} md={10.5}>
                                    <Typography variant='h4' color={"primary.contrastText"} >{item.title}</Typography>
                                    <Typography variant='caption2' color={"primary.contrastText"} >{item.subtitle}</Typography>
                                </Grid>
                                </Grid>
                            </Grid>
                            ))
                        }
                    </Grid>
                </Box>
        </Container>
    )
}


export default Benefits ;