import React from "react";


import { Box, CardContent, Grid, Typography, Card} from '@mui/material';
import PropTypes from 'prop-types';

import sixsigma from '../assets/sigma_logo.png';

import Clock from '../assets/clock.png';
import presentationCourses from '../assets/presentation_board_courses.png';
import Calendar from '../assets/calendar.png';
import Usergroup from '../assets/user_group.png';
import Award from '../assets/award.png';




const CoursesComp = ({hours,weeks, certifiedTitle, certifiedDetail,assesmentDetail,keyasepectDetail,attendDetail}) => {
    return(
        <Card>
            <CardContent>
                <Box sx={{ flexGrow: 1 }}>
                  <Grid 
                    container 
                    spacing={2}
                    display="flex"
                    justifyContent={"center"}
                    >
                        <Grid item xs={12} sm={12} md={3}
                            display="flex"
                            justifyContent="center"
                            alignItems="center"
                            alignContent="center"
                        >
                            <Box sx={{ flexGrow: 1 ,border:'solid' ,padding:"30px",borderRadius:'10px'}}>
                                <Grid container spacing={2}>
                                        <Grid item xs={12}>
                                            <img 
                                                alt='sixsigma'
                                                src={sixsigma}
                                            />
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Grid 
                                                    container
                                                >
                                                <Grid item xs={3}>
                                                    <img
                                                    alt='Clock'
                                                    src={Clock}
                                                    //  style={{width:"30px" ,height:'30px'}}
                                                    />
                                                </Grid>
                                                <Grid item  xs={9}>
                                                    <Typography variant='link2' color={"secondary.contrastText"} >{hours}</Typography>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Grid 
                                                    container
                                                >
                                                <Grid item xs={3}>
                                                    <img
                                                    alt='presentationCourses'
                                                    src={presentationCourses}
                                                    //  style={{width:"30px" ,height:'30px'}}
                                                    />
                                                </Grid>
                                                <Grid item  xs={9}>
                                                    <Typography variant='link2' color={"secondary.contrastText"} >Weekend training</Typography>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                        <Grid item xs={12}>
                                                <Grid 
                                                        container
                                                    >
                                                    <Grid item xs={3}>
                                                        <img
                                                        alt='Calendar'
                                                        src={Calendar}
                                                        //  style={{width:"30px" ,height:'30px'}}
                                                        />
                                                    </Grid>
                                                    <Grid item  xs={9}>
                                                        <Typography variant='link2' color={"secondary.contrastText"} >{weeks}</Typography>
                                                    </Grid>
                                                </Grid>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Grid 
                                                    container
                                                >
                                                <Grid item xs={3}>
                                                    <img
                                                    alt='Usergroup'
                                                    src={Usergroup}
                                                    //  style={{width:"30px" ,height:'30px'}}
                                                    />
                                                </Grid>
                                                <Grid item  xs={9}>
                                                    <Typography variant='link2' color={"secondary.contrastText"} >instructor-led training</Typography>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Grid 
                                                    container
                                                >
                                                <Grid item xs={3}>
                                                    <img
                                                    alt='Award'
                                                    src={Award}
                                                    //  style={{width:"30px" ,height:'30px'}}
                                                    />
                                                </Grid>
                                                <Grid item  xs={9}>
                                                    <Typography variant='link2' color={"secondary.contrastText"} >Certificate of Completion</Typography>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Box>
                        </Grid>
                        <Grid item xs={12} sm={12} md={9}
                            display="flex"
                            justifyContent="center"
                            alignItems="center"
                            alignContent="center"
                        >
                            <Box sx={{ flexGrow: 1 }}>
                                <Grid container spacing={2}>
                                    <Grid item xs={12}>
                                        <Typography variant='subtitle2' color={"secondary.contrastText"} >
                                            {certifiedTitle}
                                        </Typography>
                                        <Typography variant='caption2' color={"secondary.contrastText"} >
                                            {certifiedDetail}
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={12}>
                                            <Typography variant='subtitle2' color={"secondary.contrastText"} >
                                            KEY ASEPECT       
                                            </Typography>
                                            <Typography variant='caption1' color={"secondary.contrastText"} >
                                            {keyasepectDetail}
                                            </Typography>
                                    </Grid>
                                    <Grid item xs={12}>
                                            <Typography variant='subtitle2' color={"secondary.contrastText"} >
                                            WHO SHOULD ATTEND?      
                                            </Typography>
                                            <Typography variant='caption2' color={"secondary.contrastText"} >
                                            {attendDetail}
                                            </Typography>
                                    </Grid>
                                    <Grid item xs={12}>
                                            <Typography variant='subtitle2' color={"secondary.contrastText"} >
                                                ASSESMENT & CERTIFICATION  
                                            </Typography>
                                            <Typography variant='caption2' color={"secondary.contrastText"} >
                                            {assesmentDetail}
                                            </Typography>
                                    </Grid>
                                </Grid>
                            </Box>
                        </Grid>
                    </Grid>
                 </Box>
            </CardContent>
        </Card>
    )
};

CoursesComp.prototype ={
    hours: PropTypes.string.isRequired,
    weeks: PropTypes.string.isRequired,
    certifiedTitle:PropTypes.string.isRequired,
    certifiedDetail:PropTypes.string.isRequired,
    keyasepectDetail:PropTypes.string.isRequired,
    attendDetail:PropTypes.string.isRequired,
    assesmentDetail:PropTypes.string.isRequired,
}

export default CoursesComp ;