import { Card, CardContent, Grid ,Box} from '@mui/material';
import { Container } from '@mui/system';
import React from 'react';
import SimpleSlider from '../../../Components/SimpleSlider';
import Carousel from './Components/Carousel';


import   HeroBackgroundImage from "../../../assets/slider_background_Image.png";


function Home(params) {
    return(
             <Box 
             sx={{flexGrow:1,
                backgroundImage:`url(${HeroBackgroundImage})`,
                backgroundSize: "cover",
                padding:'10px'
             }}
             >
                <Grid container >
                    <Grid item xs={12} sm={12} md={12}>
                                <Carousel/>
                    </Grid>
                </Grid>
             </Box>
    )
}

export default Home;