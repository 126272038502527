import React, { useState } from 'react'
import { Box,Container,Grid,Typography} from '@mui/material';
// import About_us from '../../../assets/about_us.png'

const About = () =>{

    return(
      <Container>
            <Box 
                sx={{ 
                    flexGrow: 1 ,
                }}
            >
                <Grid container spacing={2}>
                    {/* <Grid 
                        item 
                        xs={12}
                        sm={12}
                        md={4}
                        display="flex"
                        justifyContent="center"
                        alignItems="center"
                        >
                        <img
                        alt='About'
                        src={About_us}
                        />
                    </Grid> */}
                    <Grid 
                        item 
                        xs={12} 
                        sm={12} 
                        md={12}
                        direction="column"
                        display="flex"
                        justifyContent="flex-start"
                        alignItems="start"
                        >
                            <Typography variant='h2' color={"secondary.contrastText"} textAlign={"start"}>ABOUT US</Typography>
                            <Typography variant='caption1' color={"secondary.contrastText"} textAlign={"start"}>
                                Team Excellence (TE) is a dedicated team of industry experts. Team Excellence is an initiative for effective and in depth Lean Six Sigma training, mentoring, coaching or consulting services.
                                We are committed to growing right fit Lean Six Sigma Professionals well equipped with hands on experience on project identification, project prioritization, project management and project presentation skills. This would lead to professionally qualified individuals contributing in different industries and also in society to solve day to day complex problems.
                                Professionals / industry experts with a combined experience of over 60+ years in various domains such as Banking, Financial services (FnA), Insurance, Manufacturing, Healthcare, Cloud sales and services and many other
                                We continually learn from experience, research and LSS application so that we can impart the in depth knowledge in the interest of the industry where it is a win win situation for society, industry and even individual professionals who will benefit to a large extent.
                                We deliver professionally developed training content that accurately and precisely measure an individual’s proficiency to the industry standards. TE’s formal training is planned in a structured manner for effective knowledge share. The course offers an in-depth training on the knowledge demonstration of Lean Six Sigma tools, to further enable employees to strategize their daily work and improve efficiency.
                                an in-depth training includes Project Roadmap with project identification workshop and Project Management Simulation, Case studies - Industry specific scenarios, Group Exercise - Collaborative sessions, assessment preparation, Project completion for certification
                            </Typography>
                    </Grid>
                </Grid>
            </Box>
      </Container>
    )
}


export default About ;