import React from 'react';
import {
    Box,
    Typography,
    Grid,
    Card,
    CardContent,
    Button,
    IconButton,
    Link
}  from '@mui/material';

import CallIcon from '@mui/icons-material/Call';
import EmailIcon from '@mui/icons-material/Email';
import LinkedInIcon from '@mui/icons-material/LinkedIn';

function  BookAppointment (){
    return (
        <Grid container  >
            <Typography 
                variant='h1'
                color={"#ffffff"}
             >
                Contact Us
            </Typography>
            <Grid item xs={12} sm={12} md={12} pt="10px">
             <Box sx={{ display:'flex' ,flexDirection:'row',}}>
                    <Box>
                    <CallIcon sx={{fontSize:"40px",color:'primary.contrastText',mr:"10px"}}/>
                    </Box>
                    <Box>
                            <Typography 
                            variant='h2'
                            color={'primary.contrastText'}
                            >
                           +91 636 482 9275
                        </Typography>
                    </Box>
                </Box>
            </Grid>
            <Grid item>
            </Grid>
            <Grid item  xs={12} sm={12} md={12} pt="10px">
              <Box sx={{ display:'flex' ,flexDirection:'row',}}>
                    <Box>
                    <EmailIcon sx={{fontSize:"40px",color:'primary.contrastText',mr:"10px"}}/>
                    </Box>
                    <Box>
                            <Typography 
                            variant='h2'
                            color={'primary.contrastText'}
                            >
                            support@team-excel.com
                        </Typography>
                    </Box>
                </Box>
            </Grid>
            <Grid item  xs={12} sm={12} md={12} pt="10px">
              <Box sx={{ display:'flex' ,flexDirection:'row',}}>
                    <Box>
                    <LinkedInIcon sx={{fontSize:"40px",color:'primary.contrastText',mr:"10px"}}/>
                    </Box>
                    <Box>
                            <Link 
                            variant='h2'
                            color={'primary.contrastText'}
                            href=' http://www.linkedin.com/in/Team-Excel'
                            
                            >
                                Team Excellence
                        </Link>
                    </Box>
                </Box>
            </Grid>
        </Grid>
      );
  }

export default BookAppointment;