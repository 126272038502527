import React,{useState,useRef } from 'react';
import {
    Box,
    Typography,
    Grid,
    Card,
    CardContent,
    TextField,
    Button,
    Alert
  }  from '@mui/material';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import emailjs from '@emailjs/browser';



  

  function  FeedbackForm (props){

    const form = useRef();

      
    const formik = useFormik({
        initialValues: {
            // FirstName: '',
            // lastName: '',
            name:'',
            to_name: '', 
            mobileno:'',
            email: '',
            // designation:'',
            message:'',
        },

        validationSchema: Yup.object({
           name: Yup.string()
           .max(50, 'Must be 15 characters or less')
           .matches(/^[A-Za-z ]*$/, 'Please enter valid name')
           .required('Required'),
        //    FirstName: Yup.string()
        //    .max(15, 'Must be 15 characters or less')
        //    .matches(/^[A-Za-z ]*$/, 'Please enter valid name')
        //    .required('Required'),
        //   lastName: Yup.string()
        //   .max(20, 'Must be 20 characters or less')
        //   .matches(/^[A-Za-z ]*$/, 'Please enter valid name')
        //   .required('Required'),
          email: Yup.string()
          .email('Invalid email address')
          .required('Required'),
          mobileno: Yup.string()
          .min(10, 'Must be 10 characters')
          .max(10, 'Must be 10 characters ')
          .matches( /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/, 'Phone number is not valid')
          .required('Required'),
        //   designation: Yup.string()
        //   .max(40, 'Must be 40 characters or less')
        //   .min(2, 'Must be 2 characters or less')
        //   .required('Required'),
        message: Yup.string()
          .max(200, 'Must be 200 characters or less')
          .min(2, 'Must be 20 characters or less')
        //   .required('Required'),
        }),
        onSubmit: (values,{resetForm}) => {
            emailjs.send('service_zzt8lzw' , 'template_9204y7e', 
                values,'owBVgWfLlWbsMlGU4')
                .then(() => {
                    
            //   console.log('email sent',values);
              resetForm({values:''})
            });
          alert("Massage send successfully")
          
          },
      });
    return (
        <Grid>
            <Typography variant='h4'  color={"#ffffff"}>
                If you have a Query 
            </Typography>
             <Grid 
                sx={{
                    pt:"15px",
                    // color:'#ffffff'
                }}>
                <Card sx={{width:"100%",borderRadius:'0px',}}>
                    <CardContent sx={{color:'#000000'}}>
                    <form ref={form} onSubmit={formik.handleSubmit}>
                        <Grid container spacing={1} >
                            <Grid item xs={12} sm={12} md={12} lg={12}>
                                <TextField
                                    id="name" 
                                    label="FullName"
                                    name="name" 
                                    variant="outlined" 
                                    fullWidth
                                    onChange={formik.handleChange}
                                    value={formik.values.name}
                                    sx={{}}
                                    />
                                     {formik.errors.name  && formik.touched.name ? (
                                     <span style={{color:'red'}}>{formik.errors.name}</span>) : null}
                            </Grid>
                            {/* <Grid item xs={6} sm={6} md={6} lg={6}>
                                <TextField
                                    id="lastName" 
                                    label="Last Name"
                                    name="lastName" 
                                    variant="outlined" 
                                    fullWidth
                                    value={formik.values.lastName}
                                    onChange={formik.handleChange}
                                    />
                                      {formik.touched.lastName && formik.errors.lastName ? (
                                     <span style={{color:'red'}}>{formik.errors.lastName}</span>
                                     ) : null}
                            </Grid> */}
                            <Grid item xs={12} sm={12} md={12} lg={12} >
                                <TextField
                                    id="email" 
                                    label="Email"
                                    name="email" 
                                    variant="outlined" 
                                    fullWidth
                                    type={'email'}
                                    value={formik.values.email}
                                    onChange={formik.handleChange}
                                    />
                                     {formik.touched.email && formik.errors.email ? (
                                     <span style={{color:'red'}}>{formik.errors.email}</span>
                                         ) : null}
                            </Grid>
                            <Grid item xs={12} sm={12} md={12} lg={12}>
                                <TextField
                                    id="mobileno" 
                                    label="Mobile No."
                                    name="mobileno" 
                                    variant="outlined" 
                                    type={'number'}
                                    fullWidth
                                    value={formik.values.mobileno}
                                    onChange={formik.handleChange}
                                    />
                                    {formik.touched.mobileno && formik.errors.mobileno ? (
                                     <span style={{color:'red'}}>{formik.errors.mobileno}</span>
                                         ) : null}
                            </Grid>
                            {/* <Grid item xs={12} sm={12} md={12} lg={12}>
                                <TextField
                                    id="designation" 
                                    label="Designation"
                                    name="designation" 
                                    variant="outlined" 
                                    fullWidth
                                    value={formik.values.designation}
                                    onChange={formik.handleChange}
                                    />
                                    {formik.touched.designation && formik.errors.designation ? (
                                     <span style={{color:'red'}}>{formik.errors.designation}</span>
                                         ) : null}
                            </Grid> */}
                            <Grid item xs={12} sm={12} md={12} lg={12}>
                                <TextField
                                    id="message" 
                                    label="Message"
                                    name="message" 
                                    variant="outlined" 
                                    fullWidth
                                    
                                    maxRows={4}
                                    inputProps={{
                                    style:{
                                            height:"81px",
                                    }   
                                    }}
                                    value={formik.values.message}
                                    onChange={formik.handleChange}
                                    />
                                    {formik.touched.message && formik.errors.message ? (
                                     <span style={{color:'red'}}>{formik.errors.message}</span>
                                         ) : null}
                            </Grid>
                        </Grid>
                        <Grid sx={{pt:"16px"}}>
                            <Button 
                            variant="contained"
                            type='submit'
                            // onClick={onsubmit}
                            // disabled={formik.isSubmitting} 
                            // href={url}
                            sx={{
                                backgroundColor:'Primary.main',
                                borderRadius:'23px',
                                width:"136px",
                                height:"46px"
                            }}
                            >
                              submit
                            </Button>
                        </Grid>
                    </form>
                    </CardContent>
                </Card>
             </Grid>
        </Grid>
      );
  }


  
  export default FeedbackForm;