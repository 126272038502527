import React, { useState } from 'react'

import { Box, CardContent, Grid, Typography,Card} from '@mui/material';

import Note from '../../../../assets/notes.png';
import Dollar from '../../../../assets/dollar_coin.png';
import Presentation from '../../../../assets/presentation_board.png';
import Brain from '../../../../assets/brain.png';

const trainData =[
    {
        id:1,
        title:'60 + Years Expertise',
        image:Note,
        subtitle:'Training is conducted specially by Highly Experienced Industry Experts as trainers with proficiency and combined rich experience of over 60 + years',
    },
    {
        id:2,
        title:'Value For Money',
        image:Dollar,
        subtitle:'Training includes Hands - on Practice, Project Identification Workshop, DMAIC Project Roadmap, Project Management Simulation, Case studies that includes Industry specific scenarios',
    },
    {
        id:3,
        title:'Collaborative And Interactive Learning',
        image:Presentation,
        subtitle:'Training Sessions with a Collaborative Sessions, Group Exercises, Brainstorming Sessions, workshops and interactive Sessions',
    },
    {
        id:4,
        title:'In Depth Understanding',
        image:Brain,
        subtitle:'Training enables individuals to directly interact with the industry expert trainers and allows you to build understanding of each topic, tool or technique covered',
    },
    
]


const Trainus = () =>{

    return(
        <Box sx={{ flexGrow: 1 }}>
        <Grid 
        container
        spacing={"10px"}
        // display="flex"
        // justifyContent="center"
        // alignItems="center"
        // alignContent="center"
        >
            <Grid item xs={12}>
               <Typography variant='h2' color={"secondary.contrastText"} textAlign={"center"}>WHY TRAIN WITH US?</Typography>
            </Grid>
            {trainData.map((item) => (
                 <Grid 
                 item 
                 xs={12}
                 sm={12}
                 md={3}
                 key={item.id}
                 >
                 <Card
                  p={"10px"}
                  sx={{
                    
                  }}
                 >
                     <CardContent
                     >
                        <Grid   p={"10px"} > 
                             <Typography variant='h4' height={"44px"}  textAlign={"center"}>{item.title}</Typography>
                        </Grid>
                        <Grid
                            p={"10px"}
                         sx={{
                             display:"flex",
                             justifyContent:"center",
                             alignItems:"center",
                             alignContent:"center"
                        }}
                        >
                            <img
                             alt='TrainImage'
                             src={item.image}
                            />
                        </Grid>
                        <Grid 
                              p={"10px"}
                              sx={{
                                 display:"flex",
                                 justifyContent:"center",
                                 alignItems:"center",
                                 alignContent:"center"
                            }}
                        >
                             <Typography variant='caption1' height={"150px"}   textAlign={"center"}>
                               {item.subtitle}
                             </Typography>
                        </Grid>
                     </CardContent>
                 </Card>
             </Grid>
            ))}
        </Grid>
      </Box>

    )
}


export default Trainus ;