import React from "react";
import PropTypes from "prop-types";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";
import Drawer from "@mui/material/Drawer";
import IconButton from "@mui/material/IconButton";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import { alpha, useTheme } from "@mui/material/styles";
import MenuIcon from "@mui/icons-material/Menu";

import TELogo from '../../../../assets/logo.png';


const navItems = [
 {
  id:1,
  name:'Home',
  link:'#'
 },
 {
  id:2,
  name:'Why train with Us',
  link:'#whytrainwithus'
 },
 {
  id:3,
  name:'Course',
  link:'#Course'
 },
 {
  id:4,
  name:'FAQ',
  link:'#FAQ'
 },
 {
  id:5,
  name:'About Us',
  link:'#aboutus'
 },
 {
  id:6,
  name:'Contact Us',
  link:'#contact'
 },
];



const Topbar = ({ onSidebarOpen, pages, colorInvert = false ,whyTrain, homesix,courses,faq,about,contact}) => {
  
  const theme = useTheme();
  const { mode } = theme.palette;

  const handleMove= (name) => {
        if  (name === "Home" && homesix?.current ) {
          window.scrollTo({
          behavior: "smooth",
          top: homesix.current.Top -10 ,
        });
      }
       if  (name === "Why train with Us" && whyTrain?.current ) {
          window.scrollTo({
          behavior: "smooth",
          top: whyTrain.current.offsetTop - 100,
        });
       }
        if  (name === "Course" && courses?.current ) {
          window.scrollTo({
          behavior: "smooth",
          top: courses.current.offsetTop - 100,
        });
      }
        if  (name === "FAQ" && faq?.current ) {
          window.scrollTo({
          behavior: "smooth",
          top: faq.current.offsetTop - 100,
        });
      }
      if  (name ==="About Us" && about?.current ) {
        window.scrollTo({
        behavior: "smooth",
        top: about.current.offsetTop - 100,
      });
      }
      if  (name === "Contact Us" && contact?.current ) {
        window.scrollTo({
        behavior: "smooth",
        top: contact.current.offsetTop - 100,
      });
      }
   
  }

  return (
    <Box
      display={"flex"}
      justifyContent={"space-between"}
      alignItems={"center"}
      width={1}
    >
      <Box
        display={"flex"}
        component="a"
        href="#Home"
        title="TELOGO"
        width={{ xs: 140, md: 160 }}
      >
        <Box
          component={"img"}
          src={TELogo}
          height={1}
          width={1}
        />
      </Box>
      <Box sx={{ display: { xs: "none", md: "flex" } }}  alignItems={"center"}>
      {navItems.map((item) => (
              <Button key={item.id} onClick={() =>handleMove(item.name)}  sx={{ color: "primary.main",px:4  }}>
                {item.name}
              </Button>
            ))}
      </Box>
      <Box sx={{ display: { xs: "block", md: "none" }, }} alignItems={"center"}>
        <Button
          onClick={() => onSidebarOpen()}
          aria-label="Menu"
          variant={"outlined"}
          sx={{
            borderRadius: 2,
            minWidth: "auto",
            padding: 1,
            backgroundColor:"primary.contrastText",
            borderColor: alpha(theme.palette.divider, 0.2),
          }}
        >
          <MenuIcon />
        </Button>
      </Box>
    </Box>
  );
};

Topbar.propTypes = {
  onSidebarOpen: PropTypes.func,
  pages: PropTypes.object,
  colorInvert: PropTypes.bool,
};

export default Topbar;
