import { Grid,Card, CardContent, Container } from '@mui/material';
import { Box} from '@mui/system';
import React from 'react';
import SimpleSlider from '../../../../Components/SimpleSlider';




const Carousel = () => {
    return(
        <Box sx={{flexGrow:1,p:2}}> 
            <Grid container>
                <Grid item xs={12} sm={12} md={12}>
                   {/* <Card sx={{border:'none',borderRadius:'0px'}} > */}
                     {/* <Container> */}
                        <SimpleSlider/>
                   {/* </Container> */}
                   {/* </Card> */}
                </Grid>
            </Grid>
        </Box>
    )
}


export default  Carousel ;
