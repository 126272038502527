import {React} from 'react';
import {ThemeProvider } from '@mui/material/styles';
import getTheme from './theme';
import { BrowserRouter, Route, Routes } from "react-router-dom";
import CssBaseline from '@mui/material/CssBaseline';
import Home from './views/LandingPage/Home/Home';
import Contact from './views/LandingPage/Contact';
import { ImportExport } from '@mui/icons-material';
import LandingPage from './views/LandingPage/LandingPage';
import WhytrainWithus from './views/LandingPage/WhytrainWithus/WhytrainWithus';
import FAQ from './views/LandingPage/FAQ/FAQ';
import Courses from './views/LandingPage/Courses/Courses';
import About from './views/LandingPage/About/About';

const routes = [
  {
      path : '/',
      element:<LandingPage/>
  },
  {
    path : '/LandingPage',
    element:<LandingPage/>
  },
  {
    path : '/Home',
    element:<Home/>
  },
  {
    path : '/whytrainwithus',
    element:<WhytrainWithus/>
  },
  {
    path : '/courses',
    element:<Courses/>
  },
  {
    path : '/FAQ',
    element:<FAQ/>
  },
  {
    path : '/aboutus',
    element:<About/>
  },
  {
    path : '/contact',
    element:<Contact/>
  },
  
]

function App() {
  return (
    <ThemeProvider theme={getTheme()}>
    <BrowserRouter>
      <Routes>
        {
          routes.map((item,i) => (
            <Route key={i} path={item.path}  element = {item.element} />
          ))
        }
      </Routes>
    </BrowserRouter>
    </ThemeProvider>
  );
}

export default App;
