import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import AppBar from '@mui/material/AppBar';
import useScrollTrigger from '@mui/material/useScrollTrigger';

import Container from '../../Components/Container';

import { Topbar, Sidebar, Footer } from './components';


const Main = ({ children, colorInvert = false, bgcolor = 'transparent',whyTrain,homesix,courses,faq,about,contact }) => {
  const theme = useTheme();
  const isMd = useMediaQuery(theme.breakpoints.up('md'), {
    defaultMatches: true,
  });

  const [openSidebar, setOpenSidebar] = useState(false);

  const handleSidebarOpen = () => {
    setOpenSidebar(true);
  };

  const handleSidebarClose = () => {
    setOpenSidebar(false);
  };

  const open = isMd ? false : openSidebar;

  const trigger = useScrollTrigger({
    disableHysteresis: true,
    threshold: 500,
  });

  // const [scrollTarget, setScrollTarget] = useState(undefined) 
  // const scrollTrigger = useScrollTrigger({ target: scrollTarget });

  return (
    <Box>
      <AppBar
        position={'sticky'}
        sx={{
          top: 0,
          backgroundColor: "primary.contrastText",
          height:"70px"
        }}
      >
        <Container paddingY={1} paddingX={5}>
          <Topbar
            onSidebarOpen={handleSidebarOpen}
            // pages={pages}
            homesix={homesix}
            whyTrain={whyTrain}
            courses={courses}
            faq={faq}
            about={about}
            contact={contact}
            colorInvert={trigger ? false : colorInvert}
          />
        </Container>
      </AppBar>
      <Sidebar
        onClose={handleSidebarClose}
        open={open}
        variant="temporary"
        // pages={pages}
      />
      <main>
      {/* <div ref={node => {
                if (node) {
                    setScrollTarget(node);
                }
            }}>
                {children}
            </div> */}
            {children}
        <Divider />
      </main>
      <Container paddingY={2} backgroundColor={"#000000"}>
        <Footer />
      </Container>
    </Box>
  );
};

Main.propTypes = {
  children: PropTypes.node,
  colorInvert: PropTypes.bool,
  bgcolor: PropTypes.string,

};

export default Main;
