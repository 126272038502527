import React from "react";
import { CardMedia, Container, Grid, Typography } from "@mui/material";

import CSSCOGO from "../../../assets/CSSC-Logo-Small.jpg"

function Cssc() {
  return (
   <Container>
     <Grid container>
      <Grid item xs={12} sm={12} md={4} justifyContent={"center"} display={"flex"}>
        <CardMedia 
            image={CSSCOGO}
            sx={{height:'250px',width:'250px'}}
        />
      </Grid>
      <Grid item xs={12} sm={12} md={8}>
        <Typography component="h1" variant="h1" color={"primary.main"}>Fully Accredited by CSSC</Typography>
        <Typography component="subtitle1" variant="subtitle1">
          Team Excellence training and certification is fully accredited by The
          Council for Six Sigma Certification (the largest industry
          accreditation provider to colleges, universities, and private training
          organizations worldwide).
        </Typography>
        <Typography component="subtitle1" variant="subtitle1">
          Industry Accepted and Recognized Worldwide Team Excellence is among
          the few to offer fully Accredited certification by CSSC. This is
          Industry Accepted and Recognized Worldwide.
        </Typography>
      </Grid>
    </Grid>
   </Container>
  );
}

export default Cssc;
